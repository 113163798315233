<template>
  <div class="image-search-container">
    <div class="title page-title">
      <img style="height: 100px; width: 100px;" :src="'images/PowerAI.svg'"/>
      <span class="page-sub-title">
        作为你专业的智能助理
      </span>
    </div>
    <div class="title page-sub-title">伴你轻松创作优质文案，与你分享创意激发灵感，陪你畅谈人生理想，助你成为更优秀地自己
    </div>
    <div class="title page-search">
      <div class=" input-box mb20">
        <a-input class="input" ref="codeInput" v-model="text" @keydown.native="handleKeyCode($event)"/>
        <span class="span" @click="search">搜</span>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      text: ''
    }
  },
  methods: {
    search() {
      this.$emit('search', this.text)
    },
    handleKeyCode(event) {
      if (event.keyCode === 13) {
        if (!event.ctrlKey) {
          event.preventDefault();
          this.search()
        }
      }
    }
  }
})
</script>


<style scoped lang="scss">
.image-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  //height: calc(100% - 200px);

  .title {
    margin-top: 15px;
  }

  .page-title {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: 40px;
    letter-spacing: 2px;

    span {
      font-size: 30px;
      background-image: linear-gradient(to right, #ff7e5f, #feb47b);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .page-sub-title {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
  }

  .page-search {
    width: 800px;
    margin-top: 30px;

    .input-box {
      position: relative;
      display: inline-block;
    }

    .input {
      padding: 0 40px 0 20px;
      width: 800px;
      height: 48px;
      font-size: 14px;
      border: 1px solid transparent;
      border-radius: 50px;
      //background: #eee;
      transition: width .5s;
      transition-delay: .1s;
    }

    .span {
      position: absolute;
      top: 9px;
      right: 5px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      color: #D9D9D9;
      text-align: center;
      background: #536976;
      border-radius: 50%;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .input:focus {
    width: 800px;
    outline: none;
    box-shadow: none;
    border: 1px solid #536976;
  }

  .input:focus + .span {
    background-color: pink;
    color: #fff;
  }
}

</style>
